import { memo, ReactNode, useEffect } from 'react';
import styles from './NotSuccessfulPayment.module.scss';
import PublicLayout from '../Layouts/PublicLayout/PublicLayout';
import Button from '../../components/Button';
import { useParams } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useGetOrderSuccessPayQuery } from '../../store/api/searchHotelService';
import { useAppDispatch } from '../../store/store';
import { setSearchHotelsState } from '../../store/slices/searchHotels';
import { HotelOrder } from '../../types/hotelOrder';
import { convertToTimestamp } from '../../utils/formatting';
import { useLangNavigate } from '../../utils/langNavigate';
import { useTranslation } from 'react-i18next';

interface SaccessfulPaymentProps {
  className?: string;
  children?: ReactNode;
}


export const NotSuccessfulPayment = memo((props: SaccessfulPaymentProps) => {
  const {
    className,
    children,
    ...otherProps
  } = props;

  const { id_order } = useParams<{ id_order: string }>();
  const { data, isLoading, error } = useGetOrderSuccessPayQuery({ order_id: id_order });
  const navigate = useLangNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && !error && data) {
      const dataOrder: HotelOrder = {
        id: data?.id,
        created_at: data?.created_at,
        city: data?.city,
        status: data?.status,
        status_label: data?.status_label,
        payment_status: data?.payment_status,
        payment_status_label: data?.payment_status_label,
        dateStart: convertToTimestamp(data?.date_from),
        dateEnd: convertToTimestamp(data?.date_to),
        persons_count: data?.persons_count,
        persons: data?.persons, // Инициализируем с одним пустым гостем
        email: data?.email,
        phone: data?.phone,
        typeHotel: 'hotel',
        comment: data?.comment,
        sum: Number(data?.sum),
        payment_method: data?.payment_method,
        locale: data?.locale,
        wish_cost: ''
      };

      dispatch(setSearchHotelsState(dataOrder));


    }
  }, [isLoading, error, navigate]);

  return (
    <PublicLayout filter={false} search={false} height={80} variant="light">

      <div className={styles.windowWrapper}>
        <div>
          <div className={styles.header}>
            <img src="/icons/error-pay.svg" alt="error-pay icon" className={styles.successIcon} />
            <div className={styles.title}>{t('notSuccessfulPayment.header')}</div>
          </div>
          <p className={styles.description}>
            {t('notSuccessfulPayment.text')}
          </p>
        </div>
        <div className={styles.containerButton}>
          <Button onClick={() => navigate(routes.hotels_booking)}
                  className={styles.button}>{t('notSuccessfulPayment.proceedToPayment')}</Button>
          <Button onClick={() => navigate(routes.hotels)} secondary={true}
                  className={styles.button}>{t('notSuccessfulPayment.toHomepage')}</Button>
        </div>


      </div>

    </PublicLayout>
  );
});
