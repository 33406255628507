import { ReactNode, useEffect, useState } from 'react';
import styles from './PlaceOrderHotelPage.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/slices/user';
import { bookingSelector } from '../../store/slices/booking';
import { fromUnixTime, getDate, getYear, getMonth } from 'date-fns';
import { IPassenger } from '../../types/passenger';
import { addIdToFields } from '../../utils/adapters';
import { Guests } from '../../components/Guests/Guests';
import { TypeHotel } from '../../components/TypeHotel/TypeHotel';
import PlaceOrderHotelSidebar from '../../components/PlaceOrderHotelSidebar/PlaceOrderHotelSidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button';
import ContactDetailsHotels from '../../components/Booking/components/ContactDetailsHotels/ContactDetailsHotels';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { resetSearchHotels, searchHotelSelector, setIdOrder, setSum } from '../../store/slices/searchHotels';
import { format } from 'date-fns';
import i18n from 'i18next';
import { enUS, ru } from 'date-fns/locale';
import BookingCommentHotels from '../../components/Booking/components/BookingCommentHotels/BookingCommentHotels';
import { updateModal } from '../../store/slices/modal';
import { useGetOrderMutation } from '../../store/api/searchHotelService';
import { reset } from '../../store/slices/datePickerHotels';
import { useLangNavigate } from '../../utils/langNavigate';


interface PlaceOrderHotelProps {
  className?: string;
  children?: ReactNode;
}

const getDefaultValues = (bookingPassengers: IPassenger[], user: IPassenger) => {
  const data = bookingPassengers.reduce((prev, curr) => {
    const passenger = addIdToFields(curr);
    return { ...prev, ...passenger };
  }, {});
  return { ...data, ...user, agreement: false, createLK: false, need_booking: false };
};


export const PlaceOrderHotelPage = (props: PlaceOrderHotelProps) => {
  const dispatch = useAppDispatch();
  const { user, token } = useSelector(userSelector);
  const { passengers: bookingPassengers } = useSelector(bookingSelector);
  const defaultValues = getDefaultValues(bookingPassengers, user);
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const [isBookingSectionShown, setBookingBlockState] = useState(false);
  const navigate = useLangNavigate();
  const [getOrder, { isLoading, data, error }] = useGetOrderMutation();
  const currency = localStorage.getItem('currency') || (i18n.language.startsWith('ru') ? 'rub' : 'eur');


  const BASE_PRICE = (process.env.REACT_APP_BASE_PRICE_HOTEL || 1399);
  const BASE_PRICE_EURO = (process.env.REACT_APP_BASE_PRICE_HOTEL_EURO || 17);


  const getSummPrice = (price: number | string) => {
    const summPrice = Number(price) * persons_count;
    return summPrice;

  };


  useEffect(() => {
    if (currency === 'rub') {
      dispatch(setSum(getSummPrice(BASE_PRICE)));
    } else {
      dispatch(setSum(getSummPrice(BASE_PRICE_EURO)));
    }

  }, [currency]);

  useEffect(() => {
    if (error)
      console.log(error);

  }, [error]);
  const {
    city,
    persons,
    persons_count,
    dateStart,
    dateEnd,
    phone,
    email,
    comment,
    sum,
    typeHotel,
    wish_cost
  } = useSelector(searchHotelSelector);
  const locale = i18n.language.startsWith('ru') ? ru : enUS;
  const isRussianLanguage = i18n.language.startsWith('ru');
  const isEnglish = i18n.language;


  const {
    className,
    children,
    ...otherProps
  } = props;

  const { t } = useTranslation();

  const daysOfWeekShort: { [key: string]: string } = {
    'понедельник': 'пн',
    'вторник': 'вт',
    'среда': 'ср',
    'четверг': 'чт',
    'пятница': 'пт',
    'суббота': 'сб',
    'воскресенье': 'вс'
  };
  const daysOfWeekShortEng: { [key: string]: string } = {
    'Monday': 'mon',
    'Tuesday': 'tue',
    'Wednesday': 'wed',
    'Thursday': 'thu',
    'Friday': 'fri',
    'Saturday': 'sat',
    'Sunday': 'sun'
  };


  const formatWithCustomDay = (date: number) => {
    const formattedDate = format(date, 'dd MMMM, EEEE', { locale: locale });
    const dayOfWeekFull = format(date, 'EEEE', { locale: locale });
    return formattedDate.replace(dayOfWeekFull, isRussianLanguage ? daysOfWeekShort[dayOfWeekFull] : daysOfWeekShortEng[dayOfWeekFull]);
  };
  useEffect(() => {
    if (city === null) {
      navigate(routes.hotels);
    }

  }, [city]);


  const handleOrder = async () => {
    try {
      // Проверка обязательных данных
      if (!city || !city.code) {
        throw new Error('Город не выбран');
      }
      if (!isEnglish) {
        throw new Error('Язык не выбран');
      }
      if (!dateStart || !dateEnd) {
        throw new Error('Даты не указаны');
      }
      if (persons_count <= 0 || persons.length === 0) {
        throw new Error('Количество гостей должно быть указано');
      }
      if (!phone || phone.trim() === '') {
        throw new Error('Номер телефона обязателен');
      }
      if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
        throw new Error('Некорректный email');
      }
      if (sum <= 0) {
        throw new Error('Сумма должна быть больше 0');
      }

      const handleStringDate = (timestamp: number) => {
        const date = fromUnixTime(timestamp);
        const month = (getMonth(date) + 1).toString().padStart(2, '0'); // Приводим месяц к двухзначному формату
        const day = getDate(date).toString().padStart(2, '0');
        const year = getYear(date);
        return `${year}-${month}-${day}`;
      };

      const response = await getOrder({
        city_code: city.code,
        date_from: handleStringDate(dateStart / 1000),
        date_to: handleStringDate(dateEnd / 1000),
        persons_count: persons_count,
        persons: persons,
        phone: phone.trim(),
        email: email.trim(),
        comment: comment,
        locale: isEnglish,
        currency: currency,
        type_hotel: typeHotel,
        wish_cost: wish_cost
      }).unwrap(); // Получаем очищенные данные
      await dispatch(setIdOrder(response.id));
      await dispatch(updateModal({ successHotel: true }));

    } catch (err) {
      console.error('Ошибка при создании заказа:', err);
    }
  };


  const handleSubmit = (data: any) => {
    handleOrder();
  };

  const handleBack = () => {
    navigate(routes.hotels);
    dispatch(resetSearchHotels());
    dispatch(reset());

  };

  return (
    <div className={styles.PlaceOrderHotel}
         {...otherProps}
    >
      <div className={styles.rootMain}>
        <div className={styles.imgHeader}>
          <Header variant="dark" />
        </div>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.containerCityInfo}>
              <Button onClick={handleBack} type="button" variant="text" className={styles.backButton}>
                <>
                  <div onClick={handleBack} className={styles.scrollArrow}>
                    <img
                      className={styles.icons16px} alt="" src="/icons/chevronLeft.svg" />
                  </div>
                  <span>
                    {t('bookingHeader.backToSearch')}
                  </span>
                </>
              </Button>
              <div className={styles.city}>{city && isRussianLanguage ? city?.city : city?.city_en}</div>
              <div className={styles.dates}>
                {dateEnd && dateStart
                  ? `${formatWithCustomDay(dateStart)} - ${formatWithCustomDay(dateEnd)}`
                  : ''}
              </div>
            </div>
            <div className={styles.children}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)} className={styles.root}>
                  <div className={styles.content}>
                    <ContactDetailsHotels user={user} />
                    <Guests guests={persons_count} />
                    <TypeHotel />
                    <BookingCommentHotels />
                  </div>
                  <div className={styles.sidebar}>
                    <PlaceOrderHotelSidebar isLoading={isLoading} />
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
