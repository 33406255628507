import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {HotelOrder} from "../../types/hotelOrder";

interface IHotelOrderSlice {
  hotelOrders: HotelOrder[],
}

const initialState: IHotelOrderSlice = {
  hotelOrders: [],
};

export type ISetHotelOrdersPayload = { hotelOrders: HotelOrder[] };

const hotelOrderSlice = createSlice({
  name: 'hotelOrder',
  initialState,
  reducers: {
    setHotelOrders: (state, { payload: { hotelOrders } }: PayloadAction<ISetHotelOrdersPayload>) => {
      state.hotelOrders = hotelOrders;
    },
  },
});

export const {
  setHotelOrders,
} = hotelOrderSlice.actions;
export default hotelOrderSlice.reducer;

export const hotelOrdersSelector = (state: RootState) => state.hotelOrder;
