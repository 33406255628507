import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Guest } from '../../types/guest';
import { Cities } from '../../types/cities';
import { HotelOrder } from '../../types/hotelOrder';
const BASE_PRICE_HOTEL = (Number(process.env.REACT_APP_BASE_PRICE_HOTEL) || 1399);
type ISetFromToPayload = { dateStart?: number | null, dateEnd?: number | null };


const initialState: HotelOrder = {
  id: '',
  created_at: '',
  city: null,
  status: 1,
  status_label: '',
  payment_status: 1,
  payment_status_label: '',
  dateStart: null,
  dateEnd: null,
  persons_count: 1,
  persons: Array(1).fill({ first_name: '', last_name: '' }), // Инициализируем с одним пустым гостем
  email: '',
  phone: '',
  typeHotel: '1',
  comment: 'Без комментариев',
  sum: BASE_PRICE_HOTEL,
  payment_method: 'card',
  locale:  null,
  wish_cost: 'Без пожеланий',
  currency: 'rub',
};

const checkIfFromEarlier = (dateStart: number | null, dateEnd: number | null): boolean => {
  if (dateStart && dateEnd) {
    return dateStart <= dateEnd;
  }
  return true;
};


const searchHotelsSlice = createSlice({
  name: 'searchHotels',
  initialState,
  reducers: {

    setSearchHotelsState: (state, { payload }: PayloadAction<HotelOrder>) => {
      return { ...state, ...payload };
    },

    setDateStart: (state, { payload: { dateStart } }: PayloadAction<ISetFromToPayload>) => {
      const _dateStart = dateStart || null;
      const areDatesCorrect = checkIfFromEarlier(_dateStart, state.dateEnd);
      const newState = areDatesCorrect ? { ...state, dateStart: _dateStart } : {
        ...state,
        dateEnd: _dateStart,
        dateStart: state.dateEnd
      };
      return newState;
    },


    setDateEnd: (state, { payload: { dateEnd } }: PayloadAction<ISetFromToPayload>) => {
      const _dateEnd = dateEnd || null;
      const areDatesCorrect = checkIfFromEarlier(state.dateStart, _dateEnd);
      const newState = areDatesCorrect ? { ...state, dateEnd: _dateEnd } : {
        ...state,
        dateEnd: state.dateStart,
        dateStart: _dateEnd
      };
      // console.log({ setTo: '', newState });
      return newState;
    },


    setSearchHotels: (state, { payload: city }: PayloadAction<Cities>) => {
      if (city || city === null) state.city = city;
    },
    setGuests: (state, { payload: guests }: PayloadAction<number>) => {
      if (guests !== undefined) {
        state.persons_count = guests;
        state.sum = BASE_PRICE_HOTEL * guests;
      }
    },
    setIdOrder: (state, { payload: orderId }: PayloadAction<string>) => {
      if (orderId !== undefined) state.id = orderId;
    },
    setSumOrder: (state, { payload: sum }: PayloadAction<number>) => {
      if (sum !== undefined) state.sum = sum;
    },
    setTypePay: (state, { payload: typePay }: PayloadAction<string>) => {
      if (typePay !== undefined) state.payment_method = typePay;
    },
    setEmail: (state, { payload: email }: PayloadAction<string>) => {
      if (email !== undefined) state.email = email;
    },
    setPhone: (state, { payload: phone }: PayloadAction<string>) => {
      if (phone !== undefined) state.phone = phone;
    },
    setSum: (state, { payload: sum }: PayloadAction<number>) => {
      if (sum !== undefined) state.sum = sum;
    },
    setWishCost: (state, { payload: wish_cost }: PayloadAction<string>) => {
      if (wish_cost !== undefined) state.wish_cost = wish_cost;
    },
    setComment: (state, { payload: commentOrder }: PayloadAction<string>) => {
      if (commentOrder !== undefined) state.comment = commentOrder;
    },
    setTypeHotel: (state, { payload: typeHotel }: PayloadAction<string>) => {
      if (typeHotel !== undefined) state.typeHotel = typeHotel;
    },
    setGuest: (state, { payload: { index, guest } }: PayloadAction<{ index: number; guest: Guest }>) => {
      // Проверяем, что индекс в пределах массива
      if (index >= 0 && index < state.persons.length) {
        state.persons[index] = guest; // Обновляем гостя по индексу
      }
    },
    // Редюсер для добавления нового гостя
    addGuest: (state) => {
      state.persons.push({ first_name: '', last_name: '' }); // Добавляем нового пустого гостя
    },
    resetSearchHotels: () => initialState
  }
});

export default searchHotelsSlice.reducer;
export const {
  setSearchHotels,
  setGuests,
  resetSearchHotels,
  setDateEnd,
  setDateStart,
  setPhone,
  setEmail,
  setComment,
  setTypeHotel,
  addGuest,
  setGuest,
  setIdOrder,
  setSum,
  setSearchHotelsState,
  setWishCost
} = searchHotelsSlice.actions;

export const searchHotelSelector = (state: RootState) => state.searchHotels;
