import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { partnerApi } from './api/partnerService';
import { userApi } from './api/userService';
import { searchApi } from './api/searchService';
import { helpApi } from './api/helpService';
import { blogApi } from './api/blogService';

import bookingReducer from './slices/booking';
import datePickerReducer from './slices/datePicker';
import datePickerReducerHotels from './slices/datePickerHotels';
import modalReducer from './slices/modal';
import orderReducer from './slices/order';
import hotelOrderReducer from './slices/hotelOrder';
import currencyReducer from './slices/currency';
import partnerReducer from './slices/partner';
import storiesReducer from './slices/stories';
import searchReducer from './slices/search';
import searchHotelReducer from './slices/searchHotels';
import userReducer from './slices/user';
import filterReducer from './slices/filter';
import { orderApi } from './api/orderService';
import { storiesApi } from './api/storiesService';
import { reviewsApi } from './api/reviewsService';
import { settingsApi } from './api/settingsService';
import reviewsReducer from './slices/reviews';
import helpReducer from './slices/help';
import blogsReducer from './slices/blog'
import { searchCitiesApi } from './api/searchHotelService';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');
    // console.log(action);
    console.warn({ title: 'Async error!', ...action.payload.data });
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    booking: bookingReducer,
    datePicker: datePickerReducer,
    datePickerHotels: datePickerReducerHotels,
    modal: modalReducer,
    order: orderReducer,
    hotelOrder: hotelOrderReducer,
    partner: partnerReducer,
    stories: storiesReducer,
    search: searchReducer,
    searchHotels: searchHotelReducer,
    user: userReducer,
    filter: filterReducer,
    help: helpReducer,
    reviews: reviewsReducer,
    blog: blogsReducer,
    currency: currencyReducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [searchCitiesApi.reducerPath]: searchCitiesApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [storiesApi.reducerPath]: storiesApi.reducer,
    [reviewsApi.reducerPath]: reviewsApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [helpApi.reducerPath]: helpApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat([
          partnerApi.middleware, userApi.middleware,
          searchApi.middleware, orderApi.middleware,
          searchCitiesApi.middleware, orderApi.middleware,
          storiesApi.middleware, reviewsApi.middleware,
          helpApi.middleware, helpApi.middleware,
          blogApi.middleware, blogApi.middleware,
          settingsApi.middleware
      ])
      .concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const reviewsSelector = (state: RootState) => state.reviews;
export const helpSelector = (state: RootState) => state.help;
export const blogSelector = (state: RootState) => state.blog;
