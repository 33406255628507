import { PARTNER_BONUS_ON_RUB } from '../../components/Partnership/PartnershipLinks/PartnershipLinks';

export const en = {
  translation: {
    navigationButton: {
      buttonHotels: 'Hotels',
      buttonAvia: 'Air ticket'
    },

    links: {
      main: 'Home',
      tariff: 'Price',
      help: 'Help',
      blog: 'Blog',
      feedback: 'Feedback',
      securityAndReturnPolicy: 'Security and Return Policy',
      rules: 'Offer Agreement',
      policy: 'Privacy Policy'
    },
    common: {
      search: 'Search',
      back: 'Back',
      noResults: 'No results',
      kupitrip: 'KupiTrip',
      showMore: 'Show more',
      loading: 'Loading...'
    },
    main: {
      header: {
        part1: 'Booking',
        part2: 'of airline tickets for visa',
        account: {
          loggedIn: 'My Account',
          login: 'Log In'
        }
      },
      age: {
        adults: {
          label: 'Adults',
          description: '12 years and above'
        },
        children: {
          label: 'Children',
          description: '2 to 12 years old'
        },
        infants: {
          label: 'Infants',
          description: 'Up to 2 years old, without a seat'
        }
      },
      searchBar: {
        labels: {
          from: 'From',
          to: 'To',
          flightDate: 'Departure Date',
          returnDate: 'Return Date',
          passengersClass: 'Passengers, Class'
        }
      },


      transfers: {
        '0': 'transfers',
        '1': 'transfer',
        '2': 'transfers',
        '5': 'transfers'
      },
      passengers: {
        '0': 'passengers',
        '1': 'passenger',
        '2': 'passengers',
        '5': 'passengers'
      },
      howWorksPart1: 'How works ',
      howWorksPart2: 'KupiTrip',
      step: 'step',
      searchAndSelectARoute: 'Search and select a route',
      searchAndSelectARouteDescription: 'In the search bar, select the desired cities, dates, and number of passengers. From the suggested options, you can book any suitable flight.',
      fillingInTheData: 'Filling in the data',
      fillingInTheDataDescription: 'Enter your passport information and contact details.',
      paymentForBooking: 'Payment for booking',
      paymentForBookingDescription: `You only pay our service fee - {{price}}, without worrying about the full ticket price. Payment can be made with any bank card`,
      orThroughSBP: ' or through SBP',
      validityPeriod: 'Validity period',
      upToSevenDays: 'up to 7 days',
      validityPeriodDescription: 'The booking will be available for up to 7 days; after this period, it will be canceled.',
      sendingCompletedDocuments: 'Sending completed documents',
      sendingCompletedDocumentsDescription: 'We will send the completed documents to the provided email within 30 minutes. Print them and attach them to your visa application or for any other purposes.',
      questionsAndAnswers: 'Questions and Answers',
      registrationSuccess: 'Registration Successful',
      thankYouForChoosingOurService: 'Thank you for choosing our service.',
      linkExpired: 'Link expired',
      linkExpiredNewSent: 'The link has expired. A new confirmation email has been sent to your email address',
      questionList: [
        {
          id: 1,
          question: 'How much do your services cost?',
          answer: 'You can check the cost of booking on our website in the <a href=\'/en/tariff\'>Price</a> section.'
        },
        {
          id: 2,
          question: 'How soon will I receive my booking after placing an order?',
          answer: 'The maximum booking issuance time is 30 minutes (we usually issue within 15 minutes).'
        },
        {
          id: 3,
          question: 'When is the best time to make a booking for a visa if the application is in a week?',
          answer: 'To ensure your booking is valid during the visa review, it\'s best to make it 1 day before submitting your application.'
        },
        {
          id: 4,
          question: 'Is your booking suitable for passing border control at the airport?',
          answer: 'Yes, the flight booking is also suitable for passing border control at the departure/arrival country. The flight itinerary receipt is provided in both English and Russian, which meets the requirements, and it contains a booking number that can be used to verify the ticket.'
        },
        {
          id: 5,
          question: 'Can I purchase my booked ticket to actually fly?',
          answer: 'No, you cannot purchase the booked ticket. To fly, you will need to buy a ticket on the ticket sales websites.'
        }
      ],
      moreQuestions: 'More questions',
      weAreAlwaysInTouch: 'We are always in touch!',
      anyQuestionsLeft: 'Any questions left?',
      anyQuestionsDescription: 'Our support service is available 24/7 without any days off. We will answer your questions about booking and help you place an order on the website.',
      writeToChat: 'Write in chat',
      writeToUsAt: 'Write to us on ',
      telegram: 'Telegram',
      ourSupportServiceIsAvailable: 'Our support service is available in popular messengers',
      feedbacks: 'Feedback',
      moreFeedbacks: 'All feedbacks'
    },
    tariff: {
      tariff: 'Price',
      airTickets: 'Air tickets',
      pricePerPassenger: 'Price per passenger:',
      rub: {
        whenPayingIn: 'When paying in rubbles:',
        description: 'When paying with a card issued by the Central Bank of the Russian Federation, the payment is in rubbles.'
      },
      eur: {
        whenPayingIn: 'When paying in euros:',
        description: 'In euros payment is made with a card issued by any bank.'
      },
      validityPeriod: 'Validity period:',
      upToDays: 'up to 7 days',
      upToDaysPopup: 'After the booking, the airline can reduce the flight ticket time limit in one direction.',
      bookingSuitable: 'Booking suitable for:',
      toApplyForAVisa: 'To apply for a visa',
      toProvideAReturnTicket: 'To provide a return ticket in the country of departure or arrival',
      forAnyOtherPurposes: 'For any other purposes where a booking is needed',
      book: 'Book',
      example: 'Booking example (PDF)'
    },
    hotels: {
      bookingTitle1: 'Booking',
      bookingTitle2: 'hotels for visa',
      howItwork: 'How it works',
      step1: {
        title: 'Step 1',
        subtitle: 'Select a city',
        description: 'In the search bar, select a city, dates, and the number of guests.'
      },
      step2: {
        title: 'Step 2',
        subtitle: 'Fill in the details',
        description: 'Provide guest details and contact information.'
      },
      step3: {
        title: 'Step 3',
        subtitle: 'Payment of the reservation',
        description: `You only pay our service fee - {{price}}, without worrying about the full booking cost.`,
        descriptionPart2: ' Payment can be made with any bank card.',
        orThroughSBP: ' Payment can be made with any bank card or through SBP'
      },
      step3second: {
        title: 'Validity period',
        subtitle: 'up to 14 days',
        description: 'The booking will be valid for up to 14 days. After this period, it will be canceled.'
      },
      step4: {
        title: 'Step 4',
        subtitle: 'Sending completed documents',
        description: 'We will send the completed documents to the provided email address within 60 minutes. Print them and\n' +
          '                    attach them to your visa application or for any other purpose.'
      },
      headerQuests: 'Questions and Answers',
      buttonMoreQuests: 'More questions',
      weAreAlwaysInTouch: {
        title: 'We are always in touch!',
        firstBlock: {
          title: 'Still have questions?',
          subtitle: 'Our support service is available 24/7 without any days off. We will answer your questions about booking and help you place an order on the website.'
        },
        secondBlock: {
          title: 'Message us on ',
          secondTitle: 'Telegram',
          subtitle: 'Our support team is available in popular messengers.'
        }
      },
      buttonReadChat: 'Message in chat',
      buttonAllFeedback: 'All reviews',
      feedbackHeader: 'Feedbacks',
      feedbackButton: 'Read more',
      answer: 'There is an answer',
      feedbackButtonText: 'All reviews',
      popularQuestionsHotels: [
        {
          id: 1,
          question: 'Can I choose a hotel?',
          answer: 'Hotel selection is not available. When placing an order, you can choose the hotel category and, if necessary, specify your location preferences in the comments.'
        },
        {
          id: 2,
          question: 'How long does the hotel reservation last?',
          answer: 'The hotel reservation lasts up to 14 days (if the check-in date is more than 20 days away).\n' +
            'If the check-in date is less than 20 days away, the duration of the reservation depends on how close the check-in date is.'
        },
        {
          id: 3,
          question: 'How much does it cost to make a hotel reservation?',
          answer: 'The cost is indicated on the <a href=\'/en/tariff\'>Price</a> page.'
        },
        {
          id: 4,
          question: 'Do I need to cancel the reservation?',
          answer:
            'The hotel reservation is automatically canceled after a certain period. No action is required from your side.'
        },
        {
          id: 5,
          question: 'How can I check my reservation?',
          answer:
            'Hotel reservations can be verified by contacting the hotel directly by phone.'
        },
        {
          id: 6,
          question: 'Can I place an order with a check-in date today?',
          answer:
            'Sure. The check-in date at the hotel can be any date.'
        },
        {
          id: 7,
          question: 'When can I expect the reservation?',
          answer: 'All orders are processed in the order they are received within 60 minutes. We will send the hotel reservation form to your email.'
        }
      ]

    },
    tariffHotels: {
      tariff: 'Hotels',
      airTickets: 'Air tickets',
      pricePerGuests: 'Price per guest:',
      rub: {
        whenPayingIn: 'When paying in rubbles:',
        description: 'When paying with a card issued by the Central Bank of the Russian Federation, the payment is in rubbles.'
      },
      eur: {
        whenPayingIn: 'When paying in euros:',
        description: 'In euros payment is made with a card issued by any bank.'
      },
      validityPeriod: 'Validity period:',
      upToDays: 'up to 14 days',
      bookingSuitable: 'Booking suitable for:',
      toApplyForAVisa: 'To apply for a visa',
      forAnyOtherPurposes: 'For any other purposes where a booking is needed',
      book: 'Book',
      example: 'Booking example (PDF)'
    },
    placeOrderHotelPage: {
      sidebar: {
        paymentInRubles: 'When paying in rubles',
        or: 'or',
        paymentInEuros: 'When paying in euros',
        validFor14Days: 'Reservation is valid for up to 14 days\n' +
          '(If there are more than 20 days until the check-in date at the time of booking)',
        amountDue: 'Amount due',
        headerDetail: 'Booking per guest'
      },
      bokingWishCost: {
        header: 'Desired hotel price (for all nights)',
        label: 'Desired hotel price (for all nights)',
        upTo: 'Up to'
      },

      typeHotel: {
        header: 'The hotel selection is done manually by managers, so the hotel will be chosen according to the selected data for:',
        paragraph1: 'city',
        paragraph2: 'hotel type',
        paragraph3: 'check-in dates',
        paragraph4: 'number of guests',
        description: 'The desired hotel type is not guaranteed and may be changed by Kupitrip managers depending on room availability and hotel occupancy.',
        label: 'Hotel Type',
        guest: 'Guest',
        labelGuestFirst: 'First Name (in Latin)',
        labelGuestLast: 'Last Name (in Latin)',
        sortOptions: [
          { id: 0, label: 'Hostel (bed in a shared room)', value: '1' },
          { id: 1, label: 'Hotel ★★', value: '2' },
          { id: 2, label: 'Hotel ★★★', value: '3' },
          { id: 3, label: 'Hotel ★★★★', value: '4' },
          { id: 4, label: 'Hotel ★★★★★', value: '5' }
        ]

      }
    },

    footer: {
      onlineServiceForBooking: 'Online service for booking airline tickets for a visa',
      help: 'Help',
      popularQuestions: 'Popular questions',
      partnership: 'Partnership',
      partnershipProgram: 'Partnership program',
      forClients: 'For clients',
      tariff: 'Price',
      blog: 'Blog',
      aviaTicket: 'Air Tickets',
      hotels: 'Hotels',
      pay: 'Payment and Refund',
      feedback: 'Feedback',
      weAreOnSocialNetworks: 'We are on social networks',
      weAccept: 'We accept',
      safetyReturnPolicy: 'Return and safety policy',
      delaemIt: 'Made in Делаем IT',
      kupiTrip: '©“KUPITRIP”2014-',
      copyright: 'KUPITRIP'
    },
    searchBar: {
      selectDepartureCity: 'Select departure city',
      selectYourArrivalCity: 'Select arrival city',
      selectDate: 'Select date',
      selectAnotherArrivalCity: 'Specify different cities',
      any: 'Any',
      departure: 'From',
      arrival: 'To',
      departureDate: 'Departure Date',
      returnDate: 'Return Date'
    },
    searchBarHotels: {
      selectDepartureCity: 'Select city',
      selectYourArrivalCity: 'Select arrival city',
      selectDate: 'Select date',
      selectAnotherArrivalCity: 'Specify different cities',
      any: 'Any',
      departure: 'From',
      arrival: 'To',
      checkInDate: 'Check-in date',
      checkOutDate: 'Check-out date',
      searchButton: 'Find'
    },
    guestSelect: {
      countGuests: 'Number of guests',
      description: 'All ages, maximum 5 people per order',
      label: 'Guests',
      getRightEndingGuests: {
        guest: 'guest',
        guestsFew: 'guests',
        guests: 'guests'
      }
    },

    bookingAdvice: {
      title: 'We recommend booking tickets on the day of submission at the border/for a visa.',
      description: 'We process orders 24/7 within 15 minutes!'
    },
    cookiesBanner: {
      agreement: 'By continuing to use our website, you agree to the information collection and cookie usage policy.',
      ok: 'OK'
    },
    feedbackCard: {
      hadAnswer: 'There is an answer',
      readMore: 'Read more'
    },
    filter: {
      filter: 'Filter',
      reset: 'Reset filter',
      showTickets: 'Show tickets',
      departureAndArrival: 'Departure and arrival',
      wayTo: 'OUTBOUND',
      wayReturn: 'RETURN',
      departureFrom: 'Departure from ',
      arrivalTo: 'Arrival to ',
      price: 'Price',
      transferDuration: 'Transfer duration',
      transfersList: {
        none: 'Non-stop',
        one: '1 transfer',
        more: '2 or more'
      },
      transfers: 'Transfers',
      airlines: 'Airlines'

    },
    loader: {
      findingTickets: 'Searching for the best tickets for you!',
      subtitle: 'Please wait........'
    },
    personalPage: {
      personalData: 'Personal Data'
    },
    feedbackPage: {
      addFeedback: 'Leave a Feedback',
      showMore: 'Show More',
      title: 'Feedback'
    },
    partnershipSettings: {
      card: {
        label: 'Card Number',
        description: 'By card number'
      },
      phone: {
        label: 'By phone number (SBP)',
        description: 'Through SBP'
      },
      req: {
        label: 'By requisites',
        description: 'Individual or legal entity'
      },
      personalInfo: {
        name: 'Name',
        surname: 'Surname',
        patronymic: 'Patronymic'
      },
      bankAccount: {
        bik: 'Bank BIC',
        checkingAccount: 'Checking Account',
        corpAccount: 'Corporate Account'
      },
      settingsSavedSuccessfully: 'Settings saved successfully',

      bankName: 'Bank Name',
      phoneLinkedToSBP: 'Phone (linked to SBP)',
      sbpBankName: 'SBP Bank Name',
      enterPhoneLinkedToSBP: 'Enter phone linked to SBP',
      recipient: 'Recipient',
      accountDetails: 'Account Details',
      save: 'Save',
      becomePartner: 'Become Partner',
      accountSettings: 'Account Settings',
      paymentMethod: 'Payment Method'
    },
    modals: {
      becomePartner: {
        registrationError: 'Registration Error',
        becomePartner: 'Become Partner',
        linkPlacement: 'Link Placement',
        link: 'Link',
        examplePlatforms: 'e.g.: Yandex Zen, personal website, YouTube channel',
        paymentMethod: 'Payment Method',
        cardNumber: 'Card Number',
        bankName: 'Bank Name'
      },
      createPartnerLink: {
        error: 'Failed to create link',
        success: 'Link successfully created',
        linkCopiedToClipboard: 'Link successfully copied to clipboard',
        newLink: 'New Link',
        linkTitle: 'Link Title',
        add: 'Add',
        cancel: 'Cancel'
      },
      deleteLink: {
        linkDeletionSuccess: 'Link successfully deleted',
        deleteLinkPrompt: 'Delete link?',
        deleteLinkPromptDescription: 'The link will no longer be available',
        delete: 'Delete',
        cancel: 'Cancel'
      },
      deletePassenger: {
        passengerDeletionSuccess: 'Passenger successfully deleted',
        header: 'Delete passenger?',
        description: 'All personal data and document data will be deleted',
        delete: 'Delete',
        cancel: 'Cancel'
      },
      feedbackCreate: {
        thankYouForFeedback: 'Thank you for your feedback',
        reviewPendingModeration: 'Your review will be published after moderation.',
        fieldRequired: 'Field is required',
        fieldMaxLength: 'Maximum character length exceeded',
        name: 'Name',
        surname: 'Surname',
        rating: 'Rating',
        feedback: 'Feedback'
      },
      createPassword: {
        done: 'Done!',
        passwordSaved: 'Your password has been successfully saved. You will be redirected to the homepage in a few moments.',
        passwordSaveError: 'Failed to save password',
        fieldRequired: 'Field is required',
        passwordMinLength: 'Password must be at least 8 characters long',
        createPasswordPrompt: 'Create a password',
        passwordMismatch: 'Passwords do not match',
        passwordLengthRequirement: 'Password must be at least 8 characters long',
        passwordCriteriaTitle: 'A password is secure if it contains:',
        latinLettersInDifferentCases: 'Latin letters in different cases',
        digits: 'Digits',
        repeatPassword: 'Repeat Password'
      },
      updatePassenger: {
        passengerSuccessfullyUpdated: 'Passenger successfully updated',
        adult12AndOlder: 'Adult, 12 years old and older',
        adultText: 'The date of birth cannot be changed as we provide search results based on passenger age type. Edit data within the passenger type or create a new search query.',
        save: 'Save'
      },
      register: {
        header: 'Check your email',
        text: 'We have sent a registration confirmation link to ',
        emailAlreadyInUseByAnotherUser: 'This email address is already in use by another user',
        register: 'Register',
        registerText: 'Enter your email and we will send a confirmation email to your address.',
        required: 'This field is required',
        minLength: 'Password must be at least 8 characters long',
        passwordRequirementLength: 'Password must be at least 8 characters long.',
        strongPasswordCriteria: 'A strong password should include:',
        latinLettersDifferentCase: 'Latin letters in different cases',
        digits: 'Digits',
        registerButton: 'Register',
        passwordMismatch: 'Passwords do not match',
        confirmPassword: 'Confirm Password',
        createPassword: 'Create a Password'
      },
      forgotPassword: {
        header: 'Change Password',
        description: 'Enter the email you used to register. We will send a link to reset your password.',
        fieldRequired: 'This field is required',
        emailLabel: 'E-mail',
        submitButton: 'Change password',
        checkYourEmail: 'Check your email',
        passwordRecoveryLinkSentTo: 'We have sent a password recovery link to',
        serverError: 'Server error'
      },
      formInfo: {
        header: 'How to Fill Out the Information',
        generalInfoTitle: 'General Information',
        generalInfoText: 'The data for booking tickets must match the passenger’s document used for travel. When entering data, all letters should be written in Latin script (using the English keyboard layout), without spaces or hyphens. Double surnames should be written as one word, without spaces or hyphens.',
        passportTitle: 'Passport',
        passportDetail1: 'As written in the document 1234567890',
        passportDetail2: 'Passport expiration date (valid until) should be provided if available',
        passportNumberLabel: 'Document Number'
      },
      paymentModal: {
        header: 'Order Payment',
        cardRuLabel: 'Russian Bank Card',
        cardRuDescription: 'Payment is only possible with a card issued by a Russian bank',
        cardLabel: 'Any Country Bank Card',
        cardDescription: 'Payment is possible with any card (except Russia). The fixed amount is in euros and does not depend on the exchange rate.',
        sbpLabel: 'SBP',
        sbpDescription: 'Fast Payment System',
        cryptoTitle: 'Cryptocurrency',
        cryptoDescriptionPart1: 'For payment, write your order number to our ',
        chatSupport: 'support chat',
        cryptoDescriptionPart2: ' or ',
        telegram: 'telegram',
        errorSelectPayment: 'Select a payment method or contact us in the support chat or telegram (for cryptocurrency)',
        rublesPaymentLabel: 'When paying in rubles',
        or: 'or',
        euroPaymentLabel: 'When paying in euros',
        howToPayInEuro: 'How to pay in Euro?',
        howToPayInEuroDetails: 'To pay by bank card in euros, you need to change the payment currency to Euro (EUR) at the top right in the "Language and currency" section. Payment by bank card is available in euros from any country, except Russia.',
        howToPayInRub: 'How to pay in Russian rubles?',
        howToPayInRubDetails: 'To pay by bank card in rubles, you need to change the payment currency to Russian ruble (RUB) at the top right in the "Language and currency" section. Payment in rubles is only available by a Russian bank card.',
        payButton: 'Pay',
        agreementTextPart1: 'I consent to the ',
        agreementLink1: 'processing of personal data',
        agreementTextPart2: ', agree to the ',
        agreementLink2: 'tariff',
        agreementTextPart3: ',',
        agreementLink3: ' terms of the offer',
        variantPayEur: 'Payment is possible in €',
        variantPayRub: 'Payment is possible in ₽'

      },
      paymentHotelModal: {
        header: 'Order Payment',
        cardRuLabel: 'Russian Bank Card',
        cardRuDescription: 'Payment is only possible with a card issued by a Russian bank',
        cardLabel: 'Any Country Bank Card',
        cardDescription: 'Payment is possible with any card (except Russia). The fixed amount is in euros and does not depend on the exchange rate.',
        sbpLabel: 'SBP',
        sbpDescription: 'Fast Payment System',
        cryptoTitle: 'Cryptocurrency',
        cryptoDescriptionPart1: 'For payment, write your order number to our ',
        chatSupport: 'support chat',
        cryptoDescriptionPart2: ' or ',
        telegram: 'telegram',
        errorSelectPayment: 'Select a payment method or contact us in the support chat or telegram (for cryptocurrency)',
        rublesPaymentLabel: 'When paying in rubles',
        or: 'or',
        euroPaymentLabel: 'When paying in euros',
        securityHeader: 'How to Avoid Scammers?',
        securityTextPart1: 'Dear travelers, we remind you that when paying for services, it is important to check the authenticity of the website (',
        kupitripLink: 'https://kupitrip.online/',
        securityTextPart2: ') and the payment system (',
        best2payLink: 'https://pay.best2pay.net/',
        and: ' and ',
        stripeLink: 'https://checkout.stripe.com/',
        securityTextPart3: '). Please be cautious when asked to follow a payment link or transfer money to a phone number/card. Scammers may steal your money this way.',
        cautionText: 'Please be cautious when asked to follow a payment link or transfer money to a phone number/card. Scammers may steal your money this way.',
        payButton: 'Pay',
        agreementTextPart1: 'I consent to the ',
        agreementLink1: 'processing of personal data',
        agreementTextPart2: ', agree to the ',
        agreementLink2: 'tariff',
        agreementTextPart3: ',',
        agreementLink3: ' terms of the offer'
      },

      paymentModalAbout: {
        orderDetails: 'Order Details',
        orderNumber: 'Order Number',
        orderAmount: 'Order Amount',
        flightDirectionTo: 'Flight Direction To',
        departureDateTo: 'Departure Date To',
        flightDirectionBack: 'Flight Direction Back',
        departureDateBack: 'Departure Date Back',
        passengerCount: 'Passenger Count'
      },
      paymentModalHotelAbout: {
        buttonExpand: 'Expand',
        buttonCollapse: 'Collapse',
        orderDetails: 'Order Details',
        orderNumber: 'Order Number',
        orderAmount: 'Order Amount',
        directionTo: 'Direction',
        departureDateTo: 'Departure Date To',
        flightDirectionBack: 'Flight Direction Back',
        departureDateBack: 'Arrival Departure Dates',
        guestsCount: 'Guests Count',
        variantsPay: 'Payment Methods'
      },

      paymentModalPassengers: {
        hidePassengers: 'Hide passengers',
        showPassengers: 'Show passengers'
      }
    },
    blogPost: {
      relatedArticles: 'Also read',
      share: 'Share',
      latestNews: 'Latest news:',
      viewAll: 'View all'
    },
    ticketDetails: {
      to: 'TO',
      back: 'BACK',
      direct: 'прямой'
    },
    noResult: {
      title: 'No suitable options',
      subtitle: 'Unfortunately, no flight options were found for your chosen destination. Try changing the dates and/or departure/arrival cities.'
    },
    payStub: {
      title: 'Payment Page',
      subtitle: 'The payment page will be displayed here',
      buttonText: 'Back'
    },
    passengersPage: {
      title: 'No Passengers Yet',
      subtitle: 'Create passengers to quickly add them to bookings',
      buttonText: 'Go to Home',
      addPassenger: 'Add Passenger',
      passengers: 'Passengers'
    },
    addPassengerPage: {
      addPassenger: 'Add Passenger',
      editPassenger: 'Edit Passenger',
      back: 'Back'
    },
    login: {
      loginToAccount: 'Login to your account',
      password: 'Password',
      register: 'Register',
      login: 'Log in',
      withGoogle: 'With Google',
      loginError: 'Login Error',
      forgotPassword: 'Forgot your password?'
    },
    ticket: {
      hideDetails: 'Hide Details',
      showDetails: 'Flight Details',
      book: 'Book',
      buy: 'Buy'
    },
    ordersPage: {
      orders: 'Orders',
      noOrders: 'No orders yet',
      subtitle: 'You have no purchased bookings, go to the homepage to find a ticket',
      buttonText: 'Go to Homepage'
    },
    partnershipPage: {
      partnershipLinks: 'Affiliate Links',
      accountSettings: 'Account Setup'
    },
    orderDetailsPage: {
      orderDocuments: 'Order Documents',
      passengers: 'Passengers',
      guests: 'Guests',
    },
    buying: {
      title: 'Please Note!',
      note: 'You are being redirected to the AIRLINE TICKET PURCHASE site (the ticket may be non-refundable). You can book the airline ticket on the previous page by clicking the \'Book\' button.',
      return: 'Return',
      consentToProceed: 'I consent to proceed to the airline ticket purchase site',
      continue: 'Continue'
    },
    partnership: {
      steps: [
        {
          step: '1',
          mainText: 'Place your partner link',
          secondaryText: 'On your website/channel/blog',
          img: '/images/partrnership1_en.png'
        },
        {
          step: '2',
          mainText: 'The user clicks the link and makes a booking',
          img: '/images/partrnership2_en.png'
        },
        {
          step: '3',
          mainText: 'You receive your commission!',
          secondaryText: 'Commission is up to ' + PARTNER_BONUS_ON_RUB + ' ₽ per passenger',
          img: '/images/partrnership3_en.png',
          accent: true
        }
      ],
      partners: [
        { name: 'Bloggers', description: '', icon: '/icons/profile.svg' },
        {
          name: 'Website Owners',
          description: 'Recommended topics: tourism, travel, regional portals, forums, etc.',
          icon: '/icons/world.svg'
        },
        {
          name: 'Social Media Group and Channel Owners',
          description: 'Telegram, VK, Facebook, etc.',
          icon: '/icons/comment.svg'
        }
      ],
      possibilities: [
        {
          main: 'Tracking Statistics',
          description: 'Analyze the number of orders and people.',
          img: '/images/partnership_1_en.png'
        },
        {
          main: 'Creating Partner Links',
          description: 'Create multiple links for different goals and purposes.',
          img: '/images/partrnership_new_en.png'
        },
        {
          main: 'Order Analytics',
          description: 'Track popular destinations, number of passengers, and order date.',
          img: '/images/partrnership_analytics_en.png'
        },
        {
          main: 'Become a KupiTrip Partner',
          description: 'Try all the features of the personal account and start earning now!',
          img: '/images/partnership_4_en.png',
          accent: true
        }
      ],
      partnerProgram: 'Partner Program',
      earningsFromFlightBookings: 'Earn up to 80 ₽ per passenger with flight bookings!',
      becomePartner: 'Become a Partner',
      goToThePartnerProgram: 'Go to the Partner Program',
      howItWorks: 'How It Works?',
      whoCanBecomePartner: 'Who Can Become a Partner?',
      opportunities: 'Opportunities'
    },
    bookingNeedTicket: {
      statusChanged: 'Booking status has been changed',
      bookingWithTicketNumberOffer: 'If you need a ticket number at the border, we offer a service to issue a booking with an electronic ticket number. This is a purchased ticket that can be verified on the airline’s website, has a booking code, and a ticket number',
      caution1: '- The booking must be made 1 day before presentation or on the same day.',
      caution2: '- The ticket is valid for 48 hours.',
      caution3: '- You do not pay for the ticket cost.',
      pricePerPerson: 'Price per person:',
      paymentInRubles: 'When paying in rubles',
      paymentInEuros: 'When paying in euros',
      bookWithTicketNumber: 'Book with a ticket number',
      bookingWithTicketNumber: 'Booking with ticket number'

    },
    passengerBookingPrice: {
      adultBooking: 'Adult booking',
      childBooking: 'Child booking',
      babyBooking: 'Infant booking'
    },
    bookingTicket: {
      noTicketSelected: 'No ticket selected for booking'
    },
    bookingSidebar: {
      paymentInRubles: 'When paying in rubles',
      or: 'or',
      paymentInEuros: 'When paying in euros',
      airlineMayReduceTimeLimit: 'After booking, the airline may unilaterally reduce the time limit for the tickets.',
      validFor7Days: 'The booking is valid for up to 7 days',
      amountDue: 'To be paid'
    },
    orderPaymentSuccess: {
      header: 'Order paid :)',
      text: 'Thank you for your order! We will generate the itinerary receipt and send it to you separately within 30 minutes.',
      passengers: 'Passengers'
    },
    successfulPayment: {
      header: 'Order paid :)',
      text: 'Thank you for your order, we will send a hotel reservation voucher to your email within 60 minutes!',
      guests: ' Guests',
      sum: 'Sum order',
      countGuests: 'Count guests',
      numberOrder: 'Number order',
      dateOrder: 'Date order',
      paid: 'Paid'
    },
    orderPaymentFail: {
      header: 'Order not paid :(',
      text: 'An error occurred with your payment, please try again.',
      proceedToPayment: 'Proceed to payment',
      toHomepage: 'To homepage'
    },
    notSuccessfulPayment: {
      header: 'Order not paid :(',
      text: 'An error occurred with your payment, please try again.',
      proceedToPayment: 'Proceed to payment',
      toHomepage: 'To homepage'
    },
    blogPosts: {
      title: 'Travel Blog',
      all: 'All'
    },
    badGateway: {
      title: 'Oops.. we’re performing maintenance, please try again in a few minutes',
      error: 'Error 502'
    },
    flightArrow: {
      transfer: 'Transfer'
    },
    suggestion: {
      noMatches: 'No matches'
    },
    orderPaymentStatus: {
      paymentFailed: 'Payment failed',
      payed: 'Paid',
      waiting: 'Waiting for payment',
      canceled: 'Canceled',
      return: 'Return',
    },
    orderStatus: {
      created: 'Created',
      expired: 'Expired',
      inWork: 'In work',
      done: 'Done',
      canceled: 'Canceled',
      onPause: 'On pause'
    },
    orderPrice: {
      total: 'Order total'
    },
    orderPassengers: {
      numberOfPassengers: 'Number of passengers',
      numberOfGuests: 'Number of guests'
    },
    orderNumber: {
      orderNumber: 'Order number'
    },
    orderDocument: {
      orderDocument: 'Booking documents',
      helpMessage: 'Booking documents will be available for download in 30 minutes',
      download: 'Download'
    },
    orderDetails: {
      notUploaded: 'Ticket not uploaded',
      pay: 'Pay'
    },
    orderDate: {
      orderDate: 'Order date'
    },
    orderCard: {
      more: 'More details',
      pay: 'Pay'
    },
    previewOrder: {
      process: 'Complete',
      cancel: 'Cancel',
      noOrderError: 'No order selected for viewing'
    },
    bookingHotel: {
      bookingHotel: "Hotel reservation",
      hotelPaymentNotCharged: "No payment is charged for the hotel",
      onlyBookingFeeCharged: "You only pay for the booking service",
      validityPeriod14Days: "Validity period - 14 days",
      hotelBookingExpires: "The hotel reservation is valid for up to 14 days and will be canceled after expiration",
      hotelVoucher: "Hotel voucher",
      hotelVoucherEmail: "We will send the hotel voucher in English to your email within 60 minutes",
      bookHotel: "Book a hotel",
    },
    bookingComment: {
      bookingComment: 'Order comment'
    },
    help: {
      header: 'Help'
    },
    notFound: {
      title: 'Page not found :(',
      subtitle: 'Try refreshing the page or go back to the homepage',
      buttonText: 'To homepage'
    },
    flightRoute: {
      routes: 'Routes',
      route: 'Route',
      days: 'd',
      hours: 'h',
      minutes: 'min'
    },
    passwordData: {
      oldPassword: 'Old password',
      passwordsDoNotMatch: 'Passwords do not match',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      changePassword: 'Change Password'
    },

    contactsData: {
      phone: 'Phone',
      contactDetails: 'Contact Details',
      loggedInWithGoogle: 'You are logged in with Google'
    },

    formSubmit: {
      personalDataSaved: 'Personal data saved successfully'
    },

    personalForm: {
      alreadyTaken: 'The specified email address is already in use by another user',
      save: 'Save',
      failedToUpdatePasswordCheckOldPassword: 'Failed to update password, check old password',
      passwordNotUpdatedCheckOldValidity: 'Password not updated, check validity of old password'
    },

    citizenship: {
      Russia: 'Russia',
      Belarus: 'Belarus',
      Ukraine: 'Ukraine',
      Kazakhstan: 'Kazakhstan'
    },

    personalCitizenship: {
      popular: 'Popular',
      allCountries: 'All Countries',
      noResults: 'No matches'
    },

    passengerType: {
      passengerType: 'Passenger Type',
      adult: {
        label: 'Adult',
        description: '12 years and older'
      },
      child: {
        label: 'Child',
        description: '2 to 12 years old'
      },
      baby: {
        label: 'Infant',
        description: 'Under 2 years old, without a seat'
      }
    },

    passengerHeader: {
      passenger: 'passenger',
      howToFillData: 'How to fill in the data'
    },

    passengerForm: {
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Delete',
      added: 'Passenger successfully created',
      updated: 'Passenger successfully updated'
    },

    passengerCard: {
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      birth: 'Date of Birth',
      citizenship: 'Citizenship',
      passportId: 'Document Number',
      edit: 'Edit',
      editPassenger: 'Edit Passenger',
      save: 'Save'
    },

    placementInput: {
      enterLink: 'Enter a valid link',
      required: 'This field is required'
    },

    placementLinks: {
      successMessage: 'Placement locations saved successfully',
      errorMessage: 'Something went wrong',
      maxEntries: 'Cannot add more than 5 entries',
      placement: 'Placement',
      link: 'Link',
      save: 'Save',
      placements: 'Placement Locations',
      addPlacement: 'Add Placement Location',
      delete: 'Delete'
    },
    register: {
      register: 'Register'
    },

    contactDetails: {
      contactDetails: 'Contact Details',
      contactDetailsDescription: 'We will send the “Booking Confirmation” document to your email',
      required: 'This field is required',
      phone: 'Phone'
    },

    securityAndReturnPolicy: {
      securityAndReturnPolicy: 'Security and Return Policy',
      paymentSecurityPolicy: 'Payment Security Policy',
      paymentOptions: 'You can pay for your order with:',
      paymentOption1: '1. International payment cards Visa, MasterCard, MIR.',
      paymentOption2: '2. Through the Fast Payment System.',
      paymentOption3: '3. Yandex Pay.',
      paymentSecurityDescription: 'Payments are processed through a secure connection using TLS 1.2 protocol. Best2pay complies with international PCI DSS requirements for secure card information processing. Your confidential payment details (card details, registration information, etc.) are not sent to the website; they are processed by the Best2pay processing center and are fully protected. No one, including the website KUPITRIP, can access the payer’s banking and personal details.',
      refundPolicy: 'Refund Policy',
      refundPolicyDescription: 'In case of service refusal, the paid amount for the service will be refunded within 10 days (Article 22 of the RF Law “On Protection of Consumer Rights” N 2300-1 of 07.02 – hereinafter Law No. 2300-1).',
      attentionNotice: 'Please note!',
      serviceCancellationPolicy: 'Service cancellation is only possible before booking the flight tickets sent to your email. Refunds are made to the same bank card used for payment.'
    },
    language: {
      header: 'Language and Currency',
      language: 'Language',
      currency: 'Currency',
      russian: 'Russian',
      english: 'English',
      rubles: 'Russian Ruble (RUB)',
      euros: 'Euro (EUR)',
      confirm: 'Confirm'
    },
    validation: {
      required: 'Field is required',
      invalidDocumentNumber: 'Invalid document number',
      invalidLinkFormat: 'There should be a link here',
      maxLength: 'Maximum length is 255 characters',
      minLength: 'Minimum password length is 8 characters',
      useLatinLettersHyphenSpaceOnly: 'Use only Latin letters, hyphen, or space',
      useLettersDotHyphenSpaceOnly: 'Use only letters, dot, hyphen, or space',
      invalidPassportNumber: 'Invalid passport number',
      invalidEmailFormat: 'Invalid email format',
      selectOneFromList: 'Select one item from the list',
      invalidBirthCertificateNumber: 'Invalid birth certificate number',
      passwordMustContainLatinLettersNumbersAndSymbols: 'Password must contain Latin letters (both cases), numbers, and symbols',
      invalidDate: 'Invalid date',
      invalidDateFormat: 'Invalid date format (use: dd.mm.yyyy)',
      dateInFutureNotAllowed: 'Future dates are not allowed',
      documentExpiryDateMustBeAfterArrivalDate: 'Document expiry date must be after arrival date',
      invalidBirthYear: 'Invalid birth year',
      dateCannotBeGreaterThan: 'Cannot be greater than',
      dateCannotBeLessThan: 'Cannot be less than',
      andCannotBeGreaterThan: 'and greater than',
      dateInThePastNotAllowed: 'Past dates are not allowed',
      onlyLatinCharactersAllowed: 'Only Latin characters are allowed',
      invalidPhoneNumberFormat: 'Invalid phone number format',
      stringMustStartWithLatinLetter: 'String must start with a Latin letter',
      stringCannotConsistOfSpaces: 'String cannot consist of spaces only',
      cardNumberMustContainAtLeast16Characters: 'Card number must contain at least 16 characters',
      minCostRub: 'Minimum cost 1 000 ₽',
      minCostEur: 'Minimum cost 10 €',
      bikMustBeNineDigits: 'Must be 9 digits',
      bankAccountMustBeTwentyDigits: 'Must be 20 digits'

    },
    partnershipLandingPage: {
      partnershipProgramTitle: 'Kupitrip Partnership Program',
      partnershipProgramSubtitle: 'Earn up to {{amount}}₽ for each passenger booked!',
      becomePartnerButton: 'Become a Partner',
      howItWorksTitle: 'How It Works',
      whoCanPartnerTitle: 'Who Can Partner',
      opportunitiesTitle: 'Opportunities'
    },
    rules: {
      offerAgreementTitle: 'Offer Agreement',
      publicOfferTitle: 'Public Offer for Concluding a Service Agreement for Booking Airline Tickets and Other Services',
      offerText: 'This Offer is an official public proposal by "KupiTrip" IP Voronin A.N., OGRN 322237500003197 (hereinafter referred to as the Agency) to conclude an Agreement between the Client and the Agency for the provision of airline ticket booking services and other services (hereinafter referred to as the Agreement) on the Agency\'s website www.kupitrip.online. The Agreement is deemed concluded and effective from the moment the individual performs the actions provided for in the offer, which signify unconditional acceptance of all terms of the offer without any exceptions or limitations, on the terms of accession.',
      termsDefinitionsTitle: '1. Terms and Definitions',
      termsDefinitionsIntro: '1.1 In this Offer, the following terms are used with the meanings given below:',
      termOffer: '1.1.1 Offer – a proposal from the Agency addressed to any individual for concluding the Agreement under the conditions contained in this Offer.',
      termPublication: '1.1.2 Publication – the placement of the text of this Offer in free access on the Internet at www.kupitrip.online/offerta',
      termAgency: '1.1.3 Agency – "KupiTrip" IP Voronin A.N., OGRN 322237500003197',
      termAgencyNotCarrier: '1.1.4 The Agency is not an airline carrier; the Agency\'s services are intermediary in nature. The Agency undertakes to provide the Client with booking services for airline tickets on regular flights of carriers and other services from suppliers, while the Client undertakes to pay the Agency for its services at the established cost in the Tariffs section.',
      termClient: '1.1.5 Client (user) – an individual making an online booking on the Agency\'s website: www.kupitrip.online',
      termCarrier: '1.1.6 Carrier – an airline company operating air transportation of passengers and baggage, licensed to carry out the type of activity subject to licensing in accordance with the legislation of the Russian Federation, providing passenger and baggage transportation services to the temporary/permanent residence point.',
      termPassenger: '1.1.7 Passenger – an individual who has concluded an air transportation agreement or an individual for whom a charter flight agreement (aircraft charter) has been concluded.',
      termContract: '1.1.8 Contract – the agreement concluded by accepting the offer between the Client and the Agency under the conditions specified below, which is an official proposal (public offer) and contains all essential terms for providing services on the Agency\'s website www.kupitrip.online',
      termOrder: '1.1.9 Order – a properly completed request by the Client for booking airline tickets or other services on the website, including details about the transportation route, dates of departure/arrival, class of service, client\'s surname, first name, patronymic, identification document details of passengers, and contact information. In the case of international travel, the passenger\'s name and surname in Latin transcription must exactly match their spelling in the passport. The itinerary receipt of the e-ticket – part of the e-ticket containing information about the passenger transportation established by the legislation of the Russian Federation and is a document certifying the conclusion of the air transportation agreement between the carrier airline and the passenger.',
      termTimeLimit: '1.1.10 Time Limit – the period for purchasing an airline ticket or hotel, established according to the rules and regulations of the Carriers or Hotel. Carriers or hotels reserve the right to change the time limit or cancel the booking at any time.',
      termClientAction: '1.1.11 Actions by the Client for ordering electronic documents via the internet resource www.kupitrip.online, email, indicate acceptance of all the terms of this Offer Agreement. By using our Site, you confirm that you are over 18 years old, legally competent, have the right to use a bank card, and enter into contractual relations with the Agency. You take responsibility for obligations arising from the execution of this agreement. If the Client disagrees with these rules and conditions, they are not entitled to use this website for booking purposes.',
      contractSubjectTitle: '2. Subject of the Agreement',
      contractSubjectAgencyObligation: '2.1 The Agency undertakes to provide the Client with intermediary services for booking airline tickets and other services.',
      contractSubjectAgencyObligationDependent: '2.2 The Agency\'s obligation to provide the booking service under this Agreement is in all cases dependent on the actual availability of seats with the Suppliers.',
      contractSubjectAgencyObligationCompleted: '2.3 From the moment the Client receives the processed documents via email, the Agency\'s obligations to the Client are considered fulfilled, and the service is deemed provided. In case of refusal by the Client of the provided service, the paid funds are non-refundable.',
      contractSubjectAgencyResponsibility: '2.4 The Agency is not responsible for the decision of the consulate/embassy regarding the issuance of a visa or guaranteed border crossing in the departure/arrival country.',
      bookingPaymentOrderTitle: '3. Booking and Payment Procedure',
      bookingPaymentOrderAgencyObligation: '3.1 The Agency\'s obligations for booking apply to confirmed and not canceled by the Client Orders containing information provided by the Client to the Agency on the website www.kupitrip.online by independently filling out the Order form. The Client\'s Order must include the following details: transportation route, dates of departure/arrival, class of service, surname, first name, patronymic, gender of clients/passengers (in case of international transportation, the passenger\'s name and surname in Latin transcription must exactly match their spelling in the passport), identification document details of passengers, and contact information for communication with the Client: phone, email, or other means of prompt communication.',
      bookingPaymentOrderClientAcceptance: '3.2 Filling out the Order form online is an acceptance of this Agreement by the Client. The Order is an instruction from the Client to perform the assignment and contains the information specified in clause 3.1 of this Agreement.',
      bookingPaymentOrderConfirmation: '3.3 After receiving payment, the Agency sends the processed documents to the Client\'s email address. The ticket booking is processed based on the identity document of the passenger in accordance with the legislation of the Russian Federation or international agreement of the Russian Federation for the transportation route provided in the air transportation agreement. The Agency is considered to have fulfilled its obligations at the moment of sending the itinerary receipt to the Client\'s email.',
      agencyObligationsTitle: '4. Agency Responsibilities',
      agencyObligationsBooking: '4.1 The Agency undertakes to book an airline ticket or other service according to the confirmed Order of the Client (except in cases of Order cancellation).',
      clientObligationsTitle: '5. Client Responsibilities',
      clientObligationsPayment: '5.1 Make timely payment and provide accurate and complete information necessary for booking airline tickets or other services as specified in clause 3.1.',
      clientObligationsContactInfo: '5.2 Provide the Agency with accurate information about their email address and phone number necessary for the Agency to contact the Client.',
      clientObligationsInform: '5.3 Inform all passengers (in case of ordering on behalf of third parties) about the content of this Agreement and all information provided by the Agency to the Client, ensure that they fulfill all the Client\'s obligations under the Agreement. The Client guarantees having the authority to act on behalf of other individuals.',
      offerValidityTitle: '6. Offer Validity and Changes',
      offerValidityEffective: '6.1 The Offer comes into force from the moment it is published on the Agency\'s website at www.kupitrip.online and remains valid until the Offer is withdrawn by the Agency.',
      offerValidityChanges: '6.2 The Agency reserves the right to make changes to the Offer terms and/or withdraw the Offer at any time at its discretion. In case of changes to the Offer, such changes take effect from the moment of publication on the Site, unless another effective date is specified upon their publication.',
      contractDurationTitle: '7. Duration, Modification, and Termination of the Agreement',
      contractDurationEffective: '7.1 The Agreement takes effect from the moment of Acceptance of the Offer and remains valid until the Agency has fulfilled its obligations for booking the service and sending documents to the Client\'s email.',
      contractDurationTermination: '7.2 This Agreement may be terminated by mutual consent of the Parties or on other grounds provided by the current legislation of the Russian Federation.',
      contractDurationCancellation: '7.3 Service cancellation is only possible before booking the airline tickets sent to the email.',
      otherConditionsTitle: '8. Other Conditions',
      otherConditionsGoverningLaw: '8.1 All issues not regulated by the Offer or the Agreement are governed by the current legislation of the Russian Federation. Disputes and disagreements arising during the execution of this Agreement will be resolved through negotiations between the parties and in a claim procedure. The claim procedure is mandatory. The response time for claims is established in accordance with the current legislation. If disputes are not resolved, the dispute will be considered in court under Russian law.',
      otherConditionsIntegrity: '8.2 Integrity of the Agreement. The Agency does not accept any conditions and obligations regarding the subject of the Offer (Order) not specified in the Offer. Exceptions may be cases where such conditions or obligations are documented in writing and signed by the Agency and the Customer.',
      otherConditionsInvalidity: '8.3 Consequences of Invalidity of Offer Conditions. If any term of the Offer is declared invalid or void by the court, this fact does not affect the validity of the Agreement.',
      otherConditionsPersonalData: 'The Client has been informed and understands the rights of the personal data subject. Carefully read the text of the public offer.'
    },
    personalData: {
      personalData: 'Personal Data',
      surnameLabel: 'Surname (in Latin script)',
      nameLabel: 'First Name (in Latin script)',
      patronymicLabel: 'Patronymic',
      noPatronymicLabel: 'No Patronymic',
      birthDateLabel: 'Date of Birth',
      genderLabel: 'Gender',
      male: 'Male',
      female: 'Female'
    },

    bookingHeader: {
      backToSearch: 'Back to Search'
    },

    flightTag: {
      direct: 'Direct'
    },

    flightTransfer: {
      transfer: 'Transfer'
    },

    scrollStories: {
      moreDetails: 'More Details'
    },

    rangeDatePicker: {
      oneWayOnly: 'One Way Only'
    },
    partnershipLinks: {
      title: 'Partnership Links',
      newLinkButton: 'New Link',
      minPayment: 'The minimum payment amount for the partnership program is 1000 rubles. According to the terms of the partnership program, you will receive up to {{amount}} rubles for each passenger in the order.',
      passengers: 'Passengers',
      orders: 'Orders',
      income: 'Income',
      expectedPayout: 'Expected payout at the end of the month',
      loading: 'Loading...',
      noLinksTitle: 'No links created',
      noLinksSubtitle: 'Create your first link to start using the partnership program',
      sortOptions: {
        allTime: 'All time',
        today: 'Today',
        sevenDays: '7 days',
        thirtyDays: '30 days'
      }
    },

    partnershipLinkDetails: {
      linkCopied: 'Link successfully copied to clipboard',
      noLinkData: 'No data available for the link',
      copyLink: 'Copy link',
      deleteLink: 'Delete link',
      passengers: 'Passengers',
      orders: 'Orders',
      income: 'Income',
      noCurrentOrdersTitle: 'No current orders',
      noCurrentOrdersSubtitle: 'No orders have been placed for this time period'
    },
    ordersLinksTable: {
      sort: 'Sort',
      passengers: 'Passengers',
      date: 'Date',
      income: 'Income',
      apply: 'Apply',
      descending: 'Descending',
      ascending: 'Ascending',
      direction: 'Direction'
    },
    partnershipPayouts: {
      totalPaid: 'Total Paid',
      noCurrentPayouts: 'No Current Payouts',
      noPayoutsForPeriod: 'No payouts for this period',
      payoutDate: 'Payout Date',
      payoutAmount: 'Payout Amount',
    },
    linksTable: {
      sort: 'Sort',
      descending: 'Descending',
      ascending: 'Ascending',
      apply: 'Apply',
      orders: 'Orders',
      passengers: 'Passengers',
      income: 'Income'
    },
    stepCard: {
      step: 'step'
    },
    personalDocument: {
      passportData: 'Passport Data',
      citizenship: 'Citizenship',
      document: 'Document',
      passportNumber: 'Document Number',
      validity: 'Validity Period',
      hasValidity: 'Validity Period',
      foreignPassportRF: 'Russian foreign passport',
      foreignPassport: 'Foreign document',
      internalPassportRF: 'Russian internal passport'
    },
    profileMenu: {
      partnerUser: 'Partner User',
      personalData: 'Personal Data',
      orders: 'Orders',
      passengers: 'Passengers',
      partnershipProgram: 'Partnership Program',
      logout: 'Logout'
    },
    confidentialPolicy: {
      processingPersonalData: 'Processing Personal Data',
      generalProvisions: '1. General Provisions',
      policyText1: 'This personal data processing policy is drafted in accordance with the requirements of Federal Law No. 152-FZ of 27.07.2006 “On Personal Data” and defines the procedures for processing personal data and measures to ensure the security of personal data for "Kupitrip" IP Voronin A.N OGRN 322237500003197 (hereinafter referred to as the Operator).',
      policyText2: 'The Operator’s primary goal and condition for conducting its activities is to respect human and citizen rights and freedoms when processing personal data, including the protection of the right to privacy and personal and family secrets. This Policy of the Operator regarding personal data processing (hereinafter referred to as the Policy) applies to all information that the Operator may receive about visitors to the website https://kupitrip.online.',
      termsDefinitions: '2. Key Terms Used in the Policy',
      automatedProcessing: 'Automated Processing of Personal Data',
      automatedProcessingDescription: 'Processing of personal data using computing technology',
      blockingData: 'Blocking of Personal Data',
      blockingDataDescription: 'Temporary cessation of processing personal data (except in cases where processing is necessary to clarify personal data)',
      website: 'Website',
      websiteDescription: 'A set of graphic and informational materials, as well as software and databases, ensuring their availability on the Internet at the network address https://kupitrip.online',
      infoSystem: 'Personal Data Information System',
      infoSystemDescription: 'A set of personal data contained in databases and information technologies and technical means ensuring their processing',
      dataAnonymization: 'Anonymization of Personal Data',
      dataAnonymizationDescription: 'Actions that make it impossible to identify the personal data of a specific User or another data subject without additional information',
      dataProcessing: 'Processing of Personal Data',
      dataProcessingDescription: 'Any action (operation) or set of actions (operations) performed using automation tools or without such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (update, modification), extraction, use, transfer (distribution, provision, access), anonymization, blocking, deletion, destruction of personal data',
      operator: 'Operator',
      operatorDescription: 'A state authority, municipal authority, legal entity, or individual that independently or jointly with others organizes and/or carries out the processing of personal data, as well as determines the purposes of processing personal data, the composition of personal data to be processed, and the actions (operations) performed with personal data',
      personalData: 'Personal Data',
      personalDataDescription: 'Any information directly or indirectly related to a specific or identifiable User of the website https://kupitrip.online',
      user: 'User',
      userDescription: 'Any visitor to the website https://kupitrip.online',
      dataProvision: 'Provision of Personal Data',
      dataProvisionDescription: 'Actions aimed at disclosing personal data to a specific person or a specific group of persons',
      dataDistribution: 'Distribution of Personal Data',
      dataDistributionDescription: 'Any actions aimed at disclosing personal data to an indefinite group of persons (transferring personal data) or making personal data available to an unlimited group of persons, including publishing personal data in mass media, placing it in information and telecommunication networks, or providing access to personal data in any other way',
      crossBorderTransfer: 'Cross-Border Transfer of Personal Data',
      crossBorderTransferDescription: 'Transfer of personal data to a foreign state authority, foreign individual, or foreign legal entity',
      dataDestruction: 'Destruction of Personal Data',
      dataDestructionDescription: 'Any actions resulting in the irreversible destruction of personal data with no possibility of further recovery of the content of personal data in the personal data information system and/or the destruction of physical carriers of personal data',
      dataProcessingPurpose: '3. The Operator May Process the Following Personal Data of the User',
      dataProcessingPurposeText1: 'Last name, first name, patronymic',
      dataProcessingPurposeText2: 'Email address',
      dataProcessingPurposeText3: 'Phone numbers',
      dataProcessingPurposeText4: 'Year, month, date, and place of birth',
      dataProcessingPurposeText5: 'Identity document details',
      dataProcessingPurposeText6: 'The site also collects and processes anonymized data about visitors (including cookies) using internet statistics services (Yandex Metrica, Google Analytics, and others)',
      dataProcessingPurposeText7: 'The above data is collectively referred to as Personal Data in this Policy',
      dataProcessingPurposeText8: 'The purpose of processing User’s personal data is to inform the User by sending emails',
      dataProcessingPurposeText9: 'The Operator also has the right to send the User notifications about new products and services, special offers, and various events',
      dataProcessingPurposeText10: 'The User may always opt-out of receiving informational messages by sending the Operator an email to info@kupitrip.online with the subject “Opt-out of notifications about new products and services and special offers”',
      dataProcessingPurposeText11: 'Anonymized data of Users, collected using internet statistics services, is used to gather information about User actions on the site, improve the quality of the site and its content',
      legalBasis: '5. Legal Grounds for Processing Personal Data',
      legalBasisText1: 'The Operator processes the User’s personal data only if it is filled out and/or sent by the User independently through special forms on the website https://kupitrip.online.',
      legalBasisText2: 'By filling out the relevant forms and/or sending their personal data to the Operator, the User agrees to this Policy.',
      legalBasisText3: 'The Operator processes anonymized data about the User if permitted by the User’s browser settings (cookie saving and JavaScript technology enabled).',
      processingOrder: '6. Procedure for Collecting, Storing, Transferring, and Other Types of Processing Personal Data',
      processingOrderText1: 'The security of personal data processed by the Operator is ensured by implementing legal, organizational, and technical measures necessary to fully comply with the requirements of current legislation on personal data protection.',
      processingOrderText2: 'The Operator ensures the security of personal data and takes all possible measures to prevent unauthorized access to personal data.',
      processingOrderText3: 'The User’s personal data will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of current legislation.',
      processingOrderText4: 'In case of inaccuracies in personal data, the User can update it independently by sending a notification to the Operator’s email address info@kupitrip.online with the subject “Personal Data Update”.',
      processingOrderText5: 'The period for processing personal data is unlimited.',
      processingOrderText6: 'The User can withdraw their consent to the processing of personal data at any time by sending a notification to the Operator via email at info@kupitrip.online with the subject “Withdrawal of Consent to Personal Data Processing”.',
      crossBorderTransferText1: 'Before commencing cross-border transfer of personal data, the Operator must ensure that the foreign state to which the transfer is planned provides reliable protection for the rights of personal data subjects.',
      crossBorderTransferText2: 'Cross-border transfer of personal data to foreign states that do not meet the above requirements may only be carried out if there is written consent from the personal data subject for the cross-border transfer of their personal data and/or the execution of a contract to which the personal data subject is a party.',
      finalProvisions: '8. Final Provisions',
      finalProvisionsText1: 'The User can obtain any clarifications regarding their personal data processing by contacting the Operator via email at info@kupitrip.online.',
      finalProvisionsText2: 'This document will reflect any changes to the Operator’s personal data processing policy. The Policy is valid indefinitely until replaced by a new version.',
      finalProvisionsText3: 'The current version of the Policy is freely available on the Internet at https://kupitrip.online/confedential_policy.'
    },
    searchTypeEnum: {
      eco: 'Economy',
      biz: 'Business'
    },
    passengersDropdown: {
      adults: 'Adults',
      adultsDescription: '12 years and older',
      children: 'Children',
      childrenDescription: 'Ages 2 to 12',
      babies: 'Infants',
      babiesDescription: 'Under 2 years, no seat'
    },
    loginCard: {
      signIn: 'Log in',
      loginToAccount: 'Login to your account',
      buttonLogin: 'Login'
    },
    partnershipLinksTable: {
      name: 'Name/Link',
      ordersTotal: 'Orders',
      passengersTotal: 'Passengers',
      incomeTotal: 'Income'
    },
    passengerTypes: {
      adult: 'adult',
      child: 'child',
      baby: 'infant'
    },
    images: {
      price: '/images/3png@3x2_en.png',
      search: '/images/1png@3x_en.png',
      passengerInfo: '/images/2png@3x_en.png',
      pdf: '/images/1png1@2x_en.png',
      telegram: '/images/1png3@3x_en.png',
      kupitripLogo: '/icons/kupitrip_logo_en.svg',
      statusPay: '/icons/status-pay-eng.svg',
      fillInHotel: '/images/image-second-step-eng.png',
      costPriceHotel: '/images/image-thirth-step-cost-eng.png'
    },
    titles: {
      bookingAirlineTicketsForVisa: 'Booking airline tickets for a visa',
      fareAndPriceForKupiTripBooking: 'Fare and price for KupiTrip booking / KupiTrip reservation',
      howMuchDoKupiTripServicesCost: 'How much do KupiTrip services cost?',
      kupiTripBookingReviews: 'KupiTrip booking, feedback'
    },
    app: {
      createPassword: {
        header: 'Create Password',
        text: 'Registration is almost complete, just create a password for your account',
        button: 'Create'
      },
      changePassword: {
        header: 'Change Password',
        text: 'Enter a new password',
        button: 'Save'
      },
      invalidLink: {
        header: 'Invalid Link',
        text: 'The link is incorrect. Get a new link'
      },
    },
    metaDescriptions: {
      bookingAirlineTicketsForVisa: 'Booking airline tickets for a visa without ticket payment. Ticket reservation without payment for 1190 rubles. Official reservation. Online booking of tickets for a visa.',
      fareAndPriceForKupiTripBooking: 'Tariff and price for KupiTrip booking/KupiTrip reservation.',
      kupiTripBookingReviews: 'Reviews about KupiTrip service. KupiTrip flight booking reviews.',
      frequentlyAskedQuestionsKupiTrip: 'Frequently asked questions about KupiTrip service. How much do KupiTrip services cost? Can I cancel my KupiTrip reservation? Official reservation.'
    },
    bookingHotelInfo: {
      noHotelPayment: 'No payment is required for the hotel',
      bookingFee: 'You only pay for the booking service',
      validityPeriod: 'Validity period - 14 days',
      bookingExpiration: 'The hotel booking is valid for up to 14 days, after which it will be canceled',
      hotelVoucher: 'Hotel voucher',
      voucherDelivery: 'We will send the hotel voucher in English to your email within 60 minutes',
      orderHotel: 'Book a hotel'
    }
  }
};
