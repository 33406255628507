import {IAirlines, ProposalsEntity} from './backend';
import { IPassenger } from './passenger';
import {Airport} from "./ticket";

export const PAYMENT_STATUS = {
  WAITING: 'wait-for-payment',
  PAYED: 'paid',
  CANCELED: 'cancel',
  RETURN: 'return_request'
} as const;

export const ORDER_STATUS = {
  CREATED: 'created',
  EXPIRED: 'expired',
  IN_WORK: 'in-work',
  DONE: 'done',
  CANCELED: 'cancel',
  ON_PAUSE: 'on-pause'
} as const;

export const ORDER_PAYMENT_TYPE = {
  CARD_RU: 'card_ru',
  CARD: 'card',
  SBP: 'sbp'
} as const;

export type IOrderPaymentStatusKeys = keyof typeof PAYMENT_STATUS;
export type IOrderPaymentStatus = typeof PAYMENT_STATUS[IOrderPaymentStatusKeys];

export type IOrderStatusKeys = keyof typeof ORDER_STATUS;
export type IOrderStatus = typeof ORDER_STATUS[IOrderStatusKeys];

export type IOrderPaymentTypeKeys = keyof typeof ORDER_PAYMENT_TYPE;
export type IOrderPaymentType = typeof ORDER_PAYMENT_TYPE[IOrderPaymentTypeKeys];

export interface IOrder {
  id: number;
  id_hash?: string;
  created_at?: string;
  updated_at?: string;
  user_id: number;
  email: string;
  phone: string;
  register?: boolean;
  status?: IOrderStatus;
  paid_status: IOrderPaymentStatus;
  ticket: { trip: ProposalsEntity | null; back?: ProposalsEntity | null };
  orderDate: number;
  passengers: IPassenger[];
  passengersCount?: number;
  comment?: string | null;
  booking_number?: string | null;
  need_booking?: boolean | null;
  airports: Airport[] | {};
  airlines: IAirlines[] | {};
  price?: number;
  price_euro?: number;
  locale?: string;
  need_hotel: boolean;
  hotel_from_date?: string | null;
  hotel_to_date?: string | null;
  hotel_type?: string;
  hotel_order_price?: number
  hotel_order_price_euro?: number
}

export interface IOrderPayment {
  order_id: number;
  type: IOrderPaymentType;
}

export interface IOrderPaymentResult {
  status: string | boolean;
  data?: string | object | null;
  message?: string;
}
